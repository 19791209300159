import {ProductDetailsDataInterface} from "../../interfaces/ProductDetailsDataInterface";
import {currencyFormatter} from "../../utils/currencyFormatter";
import {LanguageInterface} from "../../interfaces/LanguageInterface";
import {Translate} from "../../utils/Translate";
import {StringTranslation} from "../../utils/StringTranslation";
import {useGetUserInfoQuery} from "../../../app/rootReducer";
// import {exportProduct} from "../../utils/Export";
// import {useState} from "react";
// import {BarLoader} from "react-spinners";

interface Props {
    data: ProductDetailsDataInterface,
    activeLanguage: LanguageInterface,
    translations: any
}

export const ProductInfoBar = (props: Props) => {

    const {data, translations, activeLanguage} = props;

    // const [loading, setLoading] = useState<boolean>(false)

    const userData = useGetUserInfoQuery()
    const {communicationEmail} = userData?.data || {communicationEmail: null}

    // const paramObject = {
    //     products: data,
    //     selectedExportType: {
    //         id: 4,
    //         label: 'Productsheet',
    //         type: 'pdf_productsheet',
    //         fileType: 'PDF',
    //         color: 'pink',
    //     },
    //     title: StringTranslation('export-product', translations, activeLanguage)
    // }

    return (
        <div className="c-product-info-bar">
            <div className="c-product-info-bar__info">
                <div className="c-product-info-bar__info__left">
                    <div className="c-product-info-bar__info__name">
                        {/*<img className={'favourites-icon'} src="/images/favourites.svg" alt="favourites"/>*/}
                        <div>
                            <span>{Translate(data.label, activeLanguage)}</span>
                        </div>
                    </div>
                    {(data.prices && data.prices.length > 0) &&
                        <div className="c-product-info-bar__info__item c-product-info-bar__info__item__price">
                            <span>{StringTranslation('price', translations, activeLanguage)}</span>
                            {!!activeLanguage &&
                            <span>{currencyFormatter(data.prices ?? "", activeLanguage)}</span>
                            }
                        </div>
                    }
                    <div className="c-product-info-bar__info__item">
                        <span>{StringTranslation('art-nr', translations, activeLanguage)}</span>
                        <span>{data.identifier}</span>
                    </div>
                    {!!data.color?.label &&
                        <div className="c-product-info-bar__info__item">
                            {!!data.color?.hex &&
                                <div className={'c-product-info-bar__info__item__color-circle'}
                                     style={{backgroundColor: data.color.hex}}/>
                            }
                            <span style={{fontWeight: 400}}>{data.color.label}</span>
                        </div>
                    }
                </div>
                <div className="c-product-info-bar__buttons-container">
                    {!!communicationEmail &&
                        <a href={'mailto:' + communicationEmail + '?subject=' + StringTranslation('request-information-subject', translations, activeLanguage) + ' ' + Translate(data.label, activeLanguage)}
                           className="c-btn c-btn--primary btn-request">
                            <span>{StringTranslation('request-information', translations, activeLanguage)}</span>
                        </a>
                    }
                    {/*<button className="c-btn btn-download-black" disabled={loading}*/}
                    {/*    onClick={() => {*/}
                    {/*        setLoading(true)*/}
                    {/*        exportProduct(paramObject).then(() => {*/}
                    {/*            setLoading(false)*/}
                    {/*        })*/}
                    {/*    }}>*/}
                    {/*    {!loading ? (*/}
                    {/*        <>*/}
                    {/*            <span>{StringTranslation('download-productsheet', translations, activeLanguage)}</span>*/}
                    {/*            <img src="/images/download.svg" alt="download"/>*/}
                    {/*        </>*/}
                    {/*    ) : (*/}
                    {/*        <div className={'c-loader'}>*/}
                    {/*            <BarLoader color={'#fff'}/>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className="c-product-info-bar__breadcrumbs">
                <span>{Translate(data.categories[0].label, activeLanguage) ?? ""}</span><span>/</span><span>{Translate(data.productFamily.label, activeLanguage) ?? ""}</span><span>/</span><span>{Translate(data.label, activeLanguage) ?? ""}</span>
            </div>
        </div>
    )

}
